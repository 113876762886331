<template>
    <div>
        <b-form inline v-on:change="onFormChange">
            <label class="w-75" for="sys-numzone">Number of Zones:</label>
            <b-form-select
                id="sys-numzone"
                class="w-25"
                v-model="form.numZones"
                :options="numZonesOptions"
            ></b-form-select>
            <label class="w-75" for="sys-noz1Cnt">Nozzle Count:</label>
            <b-form-input
                id="sys-noz1Cnt"
                class="w-25"
                type="number"
                v-model="form.noz1Count"
                required
            ></b-form-input>
            <label class="w-75" for="sys-mistd">Cycle On Default:</label>
            <b-form-input
                id="sys-mistd"
                class="w-25"
                type="number"
                v-model="form.mistDefault"
                required
            ></b-form-input>
            <label class="w-75" for="sys-mistdoff">Cycle Off Default:</label>
            <b-form-input
                id="sys-mistdoff"
                class="w-25"
                type="number"
                v-model="form.mistOffDefault"
                required
            ></b-form-input>
            <label class="w-75" for="sys-tempdefault">Temperature Default:</label>
            <b-form-input
                id="sys-tempdefault"
                class="w-25"
                type="number"
                v-model="form.tempDefault"
                required
            ></b-form-input>
            <label class="w-50" for="sys-skiprain">Skip Rain:</label>
            <b-checkbox id="sys-skiprain" v-model="form.skipRain"></b-checkbox>
            <label class="w-50" for="sys-skipwind">Skip Wind:</label>
            <b-checkbox id="sys-skipwind" v-model="form.skipWind"></b-checkbox>
        </b-form>
    </div>
</template>

<script>
import { EventBus } from '@/plugins/event-bus';

export default {
    name: "CfgSettingsCoolMist",
    data() {
        return {
            form: {
                numZones: 1,
                noz1Count: 32,
                noz2Count: 0,
                mistDefault: 30,
                mistOffDefault: 30,
                tempDefault: 85,
                skipRain: false,
                skipWind: false
            },
            numZonesOptions: [
                {value: 1, text: "1"},
                {value: 2, text: "2"},
            ]
        }
    },
    created() {

    },
    mounted() {
        EventBus.$on("UpdateDeviceCfgCool", () => {
            const cfg = this.$store.getters.deviceCfg;
            this.form.numZones = cfg.numZones;
            this.form.noz1Count = cfg.noz1Count;
            this.form.noz2Count = cfg.noz2Count;
            this.form.mistDefault = cfg.mistDefault;
            this.form.mistOffDefault = cfg.mistOffDefault;
            this.form.tempDefault = cfg.tempDefault;
            this.form.skipRain = cfg.skipRain;
            this.form.skipWind = cfg.skipWind;

        });
        EventBus.$on("NewDeviceCfgCool", () => {
            this.$store.commit('storeDeviceCfg', this.form);
        });
    },
    beforeDestroy() {
        EventBus.$off("UpdateDeviceCfgCool");
        EventBus.$off("NewDeviceCfgCool");
    },
    methods: {
        onFormChange() {
            this.$store.commit('storeDeviceCfg', this.form);
            //console.log(this.form);
        }
    },
    computed: {

    }
}
</script>

<style scoped>

</style>
