<template>
    <div>
        <div class="text-center">
            <b-table
                striped
                bordered
                small
                hover
                sticky-header="true"
                selectable
                select-mode="multi"
                head-variant="light"
                responsive="sm"
                :items="items"
                :fields="fields"
                @row-selected="onRowSelected"
            >
                <template #cell(selected)="{ rowSelected }">
                    <template v-if="rowSelected">
                        <span aria-hidden="true">&check;</span>
                        <span class="sr-only">Selected</span>
                    </template>
                    <template v-else>
                        <span aria-hidden="true">&nbsp;</span>
                        <span class="sr-only">Not selected</span>
                    </template>
                </template>
            </b-table>
            <hr>
        </div>
    </div>
</template>

<script>
export default {
    name: "SchTemplate",
    data() {
        return {
            fields: [
                {key: 'selected', label: 'Selected', thStyle: { width: '5rem'}},
                {key: 'name', label: 'Name'}
            ],
            selected: []
        }
    },
    created() {
        this.$store.dispatch('getTemplateSchedules');
    },
    mounted() {

    },
    methods: {
        onRowSelected(items) {
            this.selected = items
            this.$store.commit('storeSelectedTemplates', this.selected);
        },
    },
    computed: {
        items() {
            const tempSch = this.$store.getters.templateSchedules;
            let templateSched = [];
            for (let i = 0; i < tempSch.length; i++) {
                if (tempSch[i].company === this.$store.getters.compTemplateSel)
                    templateSched.push(tempSch[i]);
            }
            return templateSched;
        }
    }
}
</script>

<style scoped>

</style>
