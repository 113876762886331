<template>
    <div>
        <b-form inline v-on:change="onFormChange">
            <label class="w-75" for="sys-numzone">Number of Zones:</label>
            <b-form-select
                id="sys-numzone"
                class="w-25"
                v-model="form.numZones"
                :options="numZonesOptions"
            ></b-form-select>
            <label class="w-75" for="sys-noz1Cnt">Zone 1 Count:</label>
            <b-form-input
                id="sys-noz1Cnt"
                class="w-25"
                type="number"
                v-model="form.noz1Count"
                required
            ></b-form-input>
            <label v-if="form.numZones === 2" class="w-75" for="sys-noz2Cnt">Zone 2 Count:</label>
            <b-form-input
                v-if="form.numZones === 2"
                id="sys-noz2Cnt"
                class="w-25"
                type="number"
                v-model="form.noz2Count"
                required
            ></b-form-input>
            <label class="w-75" for="sys-avgflow">Average Flow:</label>
            <b-form-input
                id="sys-avgflow"
                class="w-25"
                type="number"
                v-model="form.avgNozFlow"
                required
            ></b-form-input>
            <label class="w-75" for="sys-flowprime">Flow Prime:</label>
            <b-form-input
                id="sys-flowprime"
                class="w-25"
                type="number"
                v-model="form.flowPrime"
                required
            ></b-form-input>
            <label class="w-75" for="sys-clogdet">Clog / Leak Detection:</label>
            <b-form-input
                id="sys-clogdet"
                class="w-25"
                type="number"
                v-model="form.clogDet"
                required
            ></b-form-input>
            <label class="w-75" for="sys-clogtol">Clog Tolerance:</label>
            <b-form-input
                id="sys-clogtol"
                class="w-25"
                type="number"
                v-model="form.clogTol"
                required
            ></b-form-input>
            <label class="w-75" for="sys-leaktol">Leak Tolerance:</label>
            <b-form-input
                id="sys-leaktol"
                class="w-25"
                type="number"
                v-model="form.leakTol"
                required
            ></b-form-input>
            <label class="w-75" for="sys-noflow">No Flow Detection:</label>
            <b-form-input
                id="sys-noflow"
                class="w-25"
                type="number"
                v-model="form.noFlowDet"
                required
            ></b-form-input>
            <label class="w-75" for="sys-tank">Tank Size:</label>
            <b-form-input
                id="sys-tank"
                class="w-25"
                type="number"
                v-model="form.tankSize"
                required
            ></b-form-input>
            <label class="w-75" for="sys-mistd">Mist Default:</label>
            <b-form-input
                id="sys-mistd"
                class="w-25"
                type="number"
                v-model="form.mistDefault"
                required
            ></b-form-input>
            <label class="w-75" for="sys-agi">Agitate:</label>
            <b-checkbox id="sys-agi" v-model="form.agitate"></b-checkbox>
            <label class="w-75" for="sys-agid">Agitation Duration:</label>
            <b-form-input
                id="sys-agid"
                class="w-25"
                type="number"
                v-model="form.agitateDur"
                required
            ></b-form-input>
            <label class="w-50" for="sys-skiprain">Skip Rain:</label>
            <b-checkbox id="sys-skiprain" v-model="form.skipRain"></b-checkbox>
            <label class="w-50" for="sys-skipwind">Skip Wind:</label>
            <b-checkbox id="sys-skipwind" v-model="form.skipWind"></b-checkbox>
        </b-form>
    </div>
</template>

<script>
import { EventBus } from '@/plugins/event-bus';

export default {
    name: "CfgSettings",
    data() {
        return {
            form: {
                numZones: 1,
                noz1Count: 32,
                noz2Count: 0,
                avgNozFlow: 42,
                flowPrime: 4,
                clogDet: 10,
                clogTol: 15,
                leakTol: 30,
                noFlowDet: 5,
                tankSize: 55,
                mistDefault: 30,
                agitate: false,
                agitateDur: 20,
                skipRain: false,
                skipWind: false
            },
            numZonesOptions: [
                {value: 1, text: "1"},
                {value: 2, text: "2"},
            ]
        }
    },
    created() {

    },
    mounted() {
        EventBus.$on("UpdateDeviceCfg", () => {
            const cfg = this.$store.getters.deviceCfg;
            this.form.numZones = cfg.numZones;
            this.form.noz1Count = cfg.noz1Count;
            this.form.noz2Count = cfg.noz2Count;
            this.form.avgNozFlow = cfg.avgNozFlow;
            this.form.flowPrime = cfg.flowPrime;
            this.form.clogDet = cfg.clogDet;
            this.form.clogTol = cfg.clogTol;
            this.form.leakTol = cfg.leakTol;
            this.form.noFlowDet = cfg.noFlowDet;
            this.form.tankSize = cfg.tankSize;
            this.form.mistDefault = cfg.mistDefault;
            this.form.agitate = cfg.agitate;
            this.form.agitateDur = cfg.agitateDur;
            this.form.skipRain = cfg.skipRain;
            this.form.skipWind = cfg.skipWind;

        });
        EventBus.$on("NewDeviceConfig", () => {
            this.$store.commit('storeDeviceCfg', this.form);
        });
    },
    beforeDestroy() {
        EventBus.$off("UpdateDeviceCfg");
        EventBus.$off("NewDeviceConfig");
    },
    methods: {
        onFormChange() {
            this.$store.commit('storeDeviceCfg', this.form);
            //console.log(this.form);
        }
    },
    computed: {

    }
}
</script>

<style scoped>

</style>
